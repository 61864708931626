<template>
  <a style="color: #fff;" class="pl-3" href="javascript:void(0);" @click="signOut">
    <font-awesome-icon icon="sign-out-alt" class="opacity-5" />
    ออกจากระบบ
  </a>
</template>

<script>
import { Auth } from "../../models";
export default {
  methods: {
    signOut() {
      Auth.signOut();

      this.$toast.info("ออกจากระบบเรียบร้อย");

      setTimeout(() => {
        location.href = "/sign-in";
      }, 1000);
    },
  },
};
</script>
