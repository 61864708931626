import Vue from "vue";
import VueToast from "vue-toast-notification";
import { BootstrapVue, BootstrapVueIcons } from "bootstrap-vue";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

import "./bootstraps/icon";
import router from "./bootstraps/router";
import store from "./bootstraps/store";
import Default from "./pages/layout/default";
import NoLayout from "./pages/layout/no-layout";
import VueMask from "v-mask";
import Vuelidate from "vuelidate";
import VueHtml2pdf from 'vue-html2pdf'
import VueSlickCarousel from "vue-slick-carousel";
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
import vuetify from '@/plugins/vuetify'
import VueQr from 'vue-qr'
import VueYoutube from 'vue-youtube'
import Carousel3d from 'vue-carousel-3d';

Vue.use(VueQr)
Vue.use(VueSlickCarousel)
Vue.use(VueHtml2pdf)
Vue.use(VueMask);
Vue.use(Vuelidate);
Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);
Vue.use(VueToast);
Vue.use(VueYoutube)
Vue.use(Carousel3d);

Vue.component("default-layout", Default);
Vue.component("no-layout", NoLayout);
Vue.component("font-awesome-icon", FontAwesomeIcon);

import App from "./App.vue";

Vue.config.productionTip = false;

new Vue({
    router,
    store,
    vuetify,
    render: h => h(App),
}).$mount("#app");